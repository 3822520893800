import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {TPStorageService} from "../storage/tp-storage.service.ts";
import {StorageKeys} from "../../utills/enums/storage-keys";
import {DropDownOptionsModel} from "../../models/general";

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
    languageOptions: DropDownOptionsModel[] = [
        {value:'en', text: 'English'},
        {value:'fr', text: 'French'},
        {value:'ca', text: 'Catalan'},
        {value:'es', text: 'Spanish'},
    ];

    language: string = this.languageOptions[0].value;
    usedLanguage: DropDownOptionsModel = this.languageOptions[0];
    constructor(
        private translateService: TranslateService,
        private tpStorageService: TPStorageService
    ) { }

    init(): void {
        this.translateService.setDefaultLang(this.languageOptions[0].value);
        const browserLang =  this.translateService.getBrowserLang();
        let useLang = this.languageOptions[0].value;
        if(this.languageOptions.map(language => language.value).includes(browserLang)) { // supporting browser language
            useLang = this.tpStorageService.readLocalStorage(StorageKeys.Language) ?? browserLang ?? this.languageOptions[0].value;
        } else {
            useLang = this.tpStorageService.readLocalStorage(StorageKeys.Language) ?? this.languageOptions[0].value;
        }
        this.translateService.use(useLang);
        this.language = useLang;
        this.usedLanguage = this.findLanguage(useLang);
    }

    changeLanguage(lang: string): void {
        this.language = lang;
        this.usedLanguage = this.findLanguage(lang);
        this.translateService.use(lang);
        this.tpStorageService.writeLocalStorage(StorageKeys.Language, lang);
    }
    findLanguage(id): DropDownOptionsModel{
        return this.languageOptions.find(language => language.value === id);
    }
}