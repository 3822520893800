export const defaultEnvironment = {
    release: {
        version: 1.41,
        date: '02/5/2025'
    },
    apiConstants: {
        idUrlPatternPlaceholder: '{id}',
        bulkIdsQueryParam: 'ids',
    },
    fileSystem: {
        emailAttachmentsDefaultPath: '/Temporary files/Email attachments/',
        temporaryFolderPath: 'Temporary files/Misc/',
        clientNamePathPlaceholder: '{client_name}',
        clientContractsUploadFolderPattern: '/Clients/{client_name}/Contracts',
        clientRequestsUploadFolder: '/Clients/{client_name}/Requests',
    },
    screenBreakpoints: {
        small: '768px',
        medium: '992px',
    },
    locales: {
        english: 'en',
    },
    roles: {
        client: 3,
        clientContact: 6,
        resource: 4,
        manager: 10,
        administrator: 1,
        siteAdministrator: 5,
        projectManager: 2,
        opsManager: 8
    },
    privilegeCodes: {
        addClient: 'ADD_CLIENT',
        deleteClient: 'DELETE_CLIENT',
        deleteClientInvoices: 'DELETE_CLIENT_INVOICES',
        editClient: 'EDIT_CLIENT',
        invoiceClientJob: 'INVOICE_CLIENT_JOB',
        viewClientsTab: 'VIEW_CLIENTS_TAB',
        viewClientInvoices: 'VIEW_CLIENT_INVOICES',

        viewCrmTab: 'VIEW_CRM_TAB',
        addLead: 'ADD_NEW_LEAD',
        deleteLead: 'DELETE_LEAD',
        createTask: 'CREATE_TASK',
        editLead: 'EDIT_LEAD',

        addProjectSpace: 'ADD_PEOPLE_PROJECT_SPACE',
        addQuote: 'ADD_QUOTE',
        chaseQuote: 'CHASE_QUOTE',
        deleteQuote: 'DELETE_QUOTE',
        sendQuote: 'SEND_QUOTE',
        viewQuote: 'VIEW_QUOTE', // where to use
        viewQuotesTab: 'VIEW_QUOTES_TAB',

        createProjectSpace: 'CREATE_PROJECT_SPACE',
        viewProjectSpacesTab: 'VIEW_PROJECTSPACES_TAB',
        
        // where to use
        createPayment: 'CREATE_PAYMENT',
        deletePayment: 'DELETE_PAYMENT',
        viewPaymentTab: 'VIEW_PAYMENT_TAB',
        viewPayment: 'VIEW_PAYMENT',

        createPO: 'CREATE_PO',
        viewPO: 'VIEW_PO',

        deleteInvoices: 'DELETE_INVOICES',
        createInvoices: 'CREATE_INVOICES',
        markAsPaidInvoices: 'MARK_AS_PAID_INVOICES', // where to use
        marInvoicesAsPaid: 'MARK_INVOICES_AS_PAID', // where to use
        viewInvoiceTab: 'VIEW_INVOICE_TAB',
        viewInvoices: 'VIEW_INVOICES', // where to use

        assignProjects: 'ASSIGN_PROJECTS', // I can only find assign tasks button instead of assign projects
        deleteProjects: 'DELETE_PROJECTS',
        markProjectAsComplete: 'MARK_PROJECT_AS_COMPLETE',
        createProject: 'NEW_PROJECT',
        viewProjects: 'VIEW_PROJECTS', // where to use
        viewProjectsTab: 'VIEW_PROJECTS_TAB',

        addResource: 'ADD_RESOURCE',
        deleteResource: 'DELETE_RESOURCE',
        editResource: 'EDIT_RESOURCE',
        viewResourcesTab: 'VIEW_RESOURCES_TAB',

         //transactions and expenses are using same component so shouldn't there be same kind of permissions on expenses transactions and all kind of transactions?
        deleteExpense: 'DELETE_EXPENSE',
        viewExpense: 'VIEW_EXPENSE', //where to use
        addExpense: 'ADD_EXPENSE',
        viewExpensesReport: 'VIEW_EXPENSES_REPORT', //where to use

        viewAccountingTab: 'VIEW_ACCOUNTING_TAB',
        canEditDeleteTransactions: 'CAN_EDIT_DELETE_TRANSACTIONS',
        canImportTransactions: 'CAN_IMPORT_TRANSACTIONS', //where to use


        viewAnalytics: 'VIEW_ANALYTICS', //where to use
        viewMiscReport: 'VIEW_MISC_REPORT', //where to use
        viewReportsTab: 'VIEW_REPORTS_TAB',
        viewSalesReport: 'VIEW_SALES_REPORT',
        canViewReports: 'CAN_VIEW_REPORTS', //where to use
        viewProductivityReports: 'VIEW_PRODUCTIVITY_REPORTS', //where to use
        viewProjectReport: 'VIEW_PROJECT_REPORT', // start from here

        viewReference: 'VIEW_REFERENCE', //where to use

        addTerms: 'ADD_TERMS', //where to use
        createNote: 'CREATE_NOTE', //where to use
        canViewLogs: 'CAN_VIEW_LOGS', //where to use
        canManageUserAndRoles: 'CAN_MANAGE_USER_ROLES',
        canManageAdminSettings: 'CAN_MANAGE_ADMIN_SETTINGS' // Should the whole admin tab be prevented or only System settings menu in admin section
    },
    adminApiSection: '/Admin',
    clientRequestsFileUploadPath: 'Client/Requests',
    categories: {
        clientIndustrySectors: 'CLIENT',
        clientContractType: 'CLIENT_CONTRACT_TYPES',
        clientContractStatus: 'CLIENT_CONTRACT_STATUSES',
        clientTags: 'CLIENT_RESOURCE_TAGS',
        leadSources: 'LEADS_SOURCE',
        leadPriority: 'LEAD_PRIORITY',
        quoteChangeReason: 'QUOTE_CHANGE_REASONS',
        crmCampaignTypes: 'CAMPAIGN_TYPES',
        resourceSpecializations: 'RESOURCE',
        resourceTags: 'CLIENT_RESOURCE_TAGS',
        software: 'CAT_TOOLS',
        absenceTypes: 'ABSENCE_TYPES',
    },
    projectStatuses: {
        completed: 'COMPLETED',
    },
    quoteStatuses: {
        rejected: 'REJECTED',
        accepted: 'ACCEPTED',
        cancelled: 'CANCELLED',
        open: 'OPEN',
    },
    clientInvoiceStatuses: {
        new: 'NEW',
        paid: 2,
        partiallyPaid: 'PARTIALLYPAID',
        sent: 'SENT',
        void: 'VOID',
    },
    resourceInvoiceStatuses: {
        dueThisMonth: 'DUETHISMONTH',
        overdue: 'OVERDUE',
        outstanding: 'OUTSTANDING',
        paid: 'PAID',
        partiallyPaid: 'PARTIALLYPAID',
        deleted: 'DELETED', new: 'NEW',
    },
    resourceStatuses: {
        active: 'ACTIVE',
        inactive: 'INACTIVE',
    },
    clientStatuses: {
        active: 1,
        deleted:2
    },
    taskStatuses: {
        completed: 'COMPLETED',
        active: 'ACTIVE',
        overdue: 'OVERDUE',
    },
    emailManager: {
        apiSection: '/EmailManager',
        quoteIdQueryParam: 'quoteId',
        chaseQuoteEmailEndpoint: 'chaseQuoteEmail',
        sendQuoteEmailEndpoint: 'sendQuoteEmail',
    },
    searchAnywhere: {
        searchApiSection: '/search',
        keywordParamName: 'keyword',
        entityTypeParamName: 'entity',
        skipParamName: 'skip',
        takeParamName: 'take',
    },
    clientCreditNotes: {
        apiSection: '/Client',
        clientIdPlaceholder: '{id}',
        creditNotesByClientIdEndpointPattern: 'clientId/{id}/creditNotes',
        addCreditNoteEndpoint: 'creditNote',
        getEditDeleteCreditNoteEndpointPattern: '{id}/creditNote',
    },
    clientContracts: {
        apiSection: '/Client',
        contractsByClientIdEndpointPattern: 'clientId/{id}/contracts',
        addContractEndpoint: 'contract',
        getEditDeleteContractEndpointPattern: '{id}/contract',
    },
    absences: {
        pendingApprovalStatus: 'Pending Approval',
        rejectedApprovalStatus: 'Rejected',
        deletedApprovalStatus: 'Deleted',
        approvedApprovalStatus: 'Approved',
    },
    companyEvents: {
        companyEventsSearchEndpoint: 'companyEvents',
        companyEventEndpoint: 'companyEvent',
    },
    resourceTeams: {
        apiSection: '/Resource',
        resourceTeamsSearchEndpoint: 'resourceTeams',
        resourceTeamEndpoint: 'resourceTeam',
    },
    reports: {
        apiSection: '/reports',
        capacityPlannerEndpoint: 'capacityPlanner',
    },
    resourceFeedback: {
        apiSection: '/Resource',
        feedbacksEndpoint: 'feedbacksForResource',
        individualFeedbackEndpoint: 'feedback',
        topServicesEndpont: 'topServices?timeFrameEnumForTotals={timeFrameEnumForTotals}',
        topVolumesEndpont: 'topVolumes?timeFrameEnumForTotals={timeFrameEnumForTotals}'
    },
    resource: {
        resourceView: '/ResourceView'
    },
    clientFeedback: {
        apiSection: '/Client',
        feedbacksEndpoint: 'feedbacksForClient',
        individualFeedbackEndpoint: 'feedback',
    },
    ratingCriteria: {
        apiSection: '/Company',
        clientRatingEndpoint: 'clientRating',
        resourceRatingEndpoint: 'resourceRating',
    },
    checklists: {
        crud: {
            idPlaceholder: '{id}',
            individualChecklistEndpointPattern: '/Admin/{id}/checklist',
            getChecklistsEndpoint: '/Admin/checklists',
            createChecklistEndpoint: '/Admin/checklist',
        },
        projectChecklistsRelationship: {
            idPlaceholder: '{id}',
            getChecklistsEndpointPattern: 'Project/{id}/projectChecklists',
            addChecklistToProjectEndpointPattern: 'api/Project/{id}/projectChecklist/add',
            updateChecklistToProjectEndpointPattern: 'api/Project/{id}/projectChecklist/update',
            removeChecklistFromProjectEndpointPattern: 'api/Project/{id}/projectChecklist/delete',
            checklistIdsQueryParamName: 'checkListIds',
        },
    },
    accounting: {
        bankAccountTransactions: {
            idPlaceholder: '{id}',
            updateTransaction: '/Accounting/{id}/bankAccountTransaction',
            getTransaction: '/Accounting/search/bankAccountTransactions',
            createTransaction: '/Accounting/bankAccountTransaction',
            deleteTransaction: '/Accounting/bankAccountTransaction',
        },
        bankAccountImportTransactions: {
            step1: '/Accounting/transactions/import/step1',
            step2: '/Accounting/transactions/import/step2',
            step3: '/Accounting/transactions/import/step3',
            undoImport: '/Accounting/transactions/import/undoImport',
        },
        bankAccounts: {
            idPlaceholder: '{id}',
            updateBankAccount: '/Accounting/{id}/bankAccount',
            getBankAccounts: '/Accounting/search/bankAccounts',
            createBankAccount: '/Accounting/bankAccount',
            deleteBankAccounts: '/Accounting/bankAccount',
        },
        accountsCategories: {
            idPlaceholder: '{id}',
            updateAccountCategory: '/Accounting/{id}/accountingCategory',
            getAccountCategory: '/Accounting/search/accountingCategories',
            createAccountCategory: '/Accounting/accountingCategory',
            deleteAccountCategory: '/Accounting/accountingCategory',
        },
        importRules: {
            idPlaceholder: '{id}',
            updateImportRule: '/Accounting/{id}/bankAccountImportRule',
            getImportRules: '/Accounting/search/bankAccountImportRules',
            createImportRule: '/Accounting/bankAccountImportRule',
            deleteImportRules: '/Accounting/bankAccountImportRule',
        },
        importOptions: {
            idPlaceholder: '{id}',
            updateImportOption: '/Accounting/{id}/bankAccountImportSetting',
            getImportOptions: '/Accounting/search/bankAccountImportSettings',
            getImportOption: '/Accounting/{id}/bankAccountImportSetting',
            createImportOption: '/Accounting/bankAccountImportSetting',
            deleteImportOptions: '/Accounting/bankAccountImportSetting',
        },
    },
    crmEvents: {
        crmEventsSearchEndpoint: '/CRM/crmEvent/search',
        crmEventReadEditDeleteEndpointPattern: '/CRM/{id}/crmEvent',
        crmEventCreateEndpoint: '/CRM/crmEvent',
        crmEventsBulkDeleteEndpoint: '/CRM/crmEvent',
    },
    crmCampaigns: {
        searchEndpoint: '/Campaign/search',
        createEndpoint: '/Campaign/campaign',
        readEndpointPattern: '/Campaign/{id}',
        editEndpointPattern: '/Campaign/{id}/campaign',
        deleteEndpointPattern: '/Campaign/{id}/campaign',
        bulkDeleteEndpoint: '/Campaign/campaign',
        sendCampaignEndpointPattern: '/Campaign/{id}/sendCampaign',
        getPreviewEndpoint: '/Campaign/preview',
        statuses: {
            draft: 'DRAFT',
            sent: 'SENT',
        },
        uploadAttachmentsPath: '/CRM campaigns/Attachments/',
    },
    emailLists: {
        searchEndpoint: '/EmailManager/segment/search',
        createEndpoint: '/EmailManager/segment',
        readEndpointPattern: '/EmailManager/{id}/segment',
        editEndpointPattern: '/EmailManager/{id}/segment',
        deleteEndpointPattern: '/EmailManager/{id}/segment',
        addMembersEndpointPattern: '/EmailManager/{id}/segmentMembers/add',
        bulkDeleteEndpoint: '/EmailManager/segment',
    },
};
